import { useClipboard } from '@mantine/hooks'

export default function Contact() {
    const clipboard = useClipboard({ timeout: 1000 })
    const submitted = useClipboard({ timeout: 3000 })

    return (
        <section
            id="contact"
            className="text-gray-400 body-font -z-50 flex flex-col align-middle items-center justify-center pt-14 pb-32"
        >
            <div className="animate-slip2 container px-5 pt-20 mx-auto flex flex-col text-center justify-center">
                <div className="flex flex-row">
                    <div className="flex flex-col w-full">
                        <h1 className="title-font sm:text-5xl text-3xl font-medium text-white selection:text-royal-yellow selection:bg-none">
                            <span className="inline-block font-written text-royal-yellow selection:text-white mt-10 md:mb-10 mx-4 ">
                                Reach
                            </span>
                            out!
                        </h1>
                    </div>
                </div>
            </div>
            <div className="container px-5 mx-auto flex flex-col sm:flex-nowrap flex-wrap items-center">
                <form
                    netlify
                    name="contact"
                    className="relative md:w-1/2 flex flex-col mt-8 md:mt-0"
                >
                    <p className="leading-relaxed mb-5 font-body text-justify selection:bg-royal-yellow selection:text-black">
                        You can contact me by simply messaging me on any of my social media
                        platforms. I’m happy to have a chat about anything, from exciting new
                        opportunities to TV recommendations 😁
                    </p>
                </form>
            </div>
        </section>
    )
}
