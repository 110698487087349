import { Timeline, Avatar } from '@mantine/core'

export default function Experience() {
    return (
        <section id="experience" className="text-gray-400 body-font -z-50 pt-32">
            <div className="animate-slip2 container px-5 mx-auto flex flex-col text-center justify-center">
                <div className="flex flex-row">
                    <div className="flex flex-col w-full">
                        <h1 className="title-font sm:text-5xl text-3xl font-medium text-white selection:text-royal-yellow selection:bg-none">
                            My
                            <span className="inline-block font-written text-royal-yellow selection:text-white mt-10 mb-7 mx-4 ">
                                Experience
                            </span>
                            so far
                        </h1>
                        <p className="lg:w-2/3 mx-auto leading-relaxed text-md text-justify font-body selection:bg-royal-yellow selection:text-black">
                            I've worked a number of jobs throughout my time in university, on top of
                            some student volunteering. Through each experience, I got to not only
                            learn valuable skills both soft and technical, but also make incredible
                            lifelong friends. Below is a breakdown of my responsibilities, past and
                            current.
                        </p>
                    </div>
                </div>
                <Timeline bulletSize={96} lineWidth={2} className="ml-2 mt-14 md:pl-20 lg:pl-56">
                    <Timeline.Item
                        bullet={<Avatar size={120} radius="100px" src="./descartes.png" />}
                    >
                        <div className="flex flex-col pl-12 pb-16 w-fit">
                            <p className="text-white text-2xl w-fit">Web Developer</p>
                            <p className="text-chill-teal text-lg w-fit">
                                Descartes Systems Group / July 2022 - Present
                            </p>
                            <ul className="font-body text-sm text-pale-gray mt-2 ml-4 list-disc">
                                <li className="my-2">
                                    Overhauled the company’s platform portal through a UI library
                                    transition, thoroughly refactoring it to be more extendable and
                                    easier to develop
                                </li>
                                <li className="my-2">
                                    Built a core feature which allows customers to reschedule and
                                    cancel appointments
                                </li>
                                <li className="my-2">
                                    Added further functionality onto bespoke software for Bunnings,
                                    one of our clients
                                </li>
                                <li className="my-2">
                                    Made numerous time-sensitive fixes and optimisations, one of
                                    which gave the company a 58% decrease per day in operating costs
                                </li>
                            </ul>
                        </div>
                    </Timeline.Item>
                    <Timeline.Item bullet={<Avatar size={120} radius="xl" src="./localz.png" />}>
                        <div className="flex flex-col pl-12 pb-16 w-fit">
                            <p className="text-white text-2xl w-fit">Web Developer Intern</p>
                            <p className="text-chill-teal text-lg w-fit">
                                Localz / August 2021 - July 2022
                            </p>
                            <ul className="font-body text-sm text-pale-gray mt-2 ml-4 list-disc">
                                <li className="my-2">
                                    Implemented a new workflows manager for the company’s platform
                                    portal
                                </li>
                                <li className="my-2">
                                    Making reusable React components to be added to an internal
                                    component library
                                </li>
                            </ul>
                        </div>
                    </Timeline.Item>
                    <Timeline.Item bullet={<Avatar size={120} radius="xl" src="./unimelb.png" />}>
                        <div className="flex flex-col pl-12 pb-16 w-fit">
                            <p className="text-white text-2xl w-fit">Database Systems &</p>
                            <p className="text-white text-2xl w-fit">OOSD Tutor</p>
                            <p className="text-chill-teal text-lg w-fit">
                                University of Melbourne / March 2021 - August 2021
                            </p>
                            <ul className="font-body text-sm text-pale-gray mt-2 ml-4 list-disc">
                                <li className="my-2">
                                    Teaching important programming concepts at a university level to
                                    classes of up to 30 students
                                </li>
                                <li className="my-2">
                                    Creating subject content with a team of tutors
                                </li>
                                <li className="my-2">Evaluating assignment and exam material</li>
                            </ul>
                        </div>
                    </Timeline.Item>
                    <Timeline.Item bullet={<Avatar size={120} radius="xl" src="./blibli.png" />}>
                        <div className="flex flex-col pl-12 pb-16 w-fit">
                            <p className="text-white text-2xl w-fit">Backend Developer Intern</p>
                            <p className="text-chill-teal text-lg w-fit">
                                PT Global Digital Niaga (Blibli.com) / December 2020 - February 2021
                            </p>
                            <ul className="font-body text-sm text-pale-gray mt-2 ml-4 list-disc">
                                <li className="my-2">
                                    Worked with the Customer Experience team to optimise one of
                                    Indonesia’s largest e-commerce platforms
                                </li>
                                <li className="my-2">
                                    Implemented ratings on product cards used by up to 20 million
                                    active users
                                </li>
                                <li className="my-2">
                                    Utilised the Spring framework on time-sensitive tasks
                                </li>
                            </ul>
                        </div>
                    </Timeline.Item>
                    <Timeline.Item bullet={<Avatar size={120} radius="xl" src="./umsuIntl.png" />}>
                        <div className="flex flex-col pl-12 w-fit">
                            <p className="text-white text-2xl w-fit">
                                Media & Communications Director
                            </p>
                            <p className="text-chill-teal text-lg w-fit">
                                University of Melbourne Student Union International / August 2020 -
                                May 2021
                            </p>
                            <ul className="font-body text-sm text-pale-gray mt-2 ml-4 list-disc">
                                <li className="my-2">
                                    Coordinated a team of officers to establish the organisation’s
                                    brand and image
                                </li>
                                <li className="my-2">
                                    Executed marketing strategies for events to an audience of up to
                                    20,000 students
                                </li>
                                <li className="my-2">
                                    Overhauled the brand with a new mascot and information booklet
                                </li>
                            </ul>
                        </div>
                    </Timeline.Item>
                </Timeline>
            </div>
        </section>
    )
}
