import React from 'react'
import { projects } from '../data'
import BrowserFrame from 'react-browser-frame'
import ProjectSlider from './ProjectSlider'
import { Badge } from '@mantine/core'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default function Projects() {
    const projectSlides = projects.map((project) => {
        return {
            render: (
                <BrowserFrame>
                    <div className="flex relative">
                        <img
                            alt={project.title}
                            className="absolute inset-0 w-[17rem] h-[30rem] md:w-[35rem] md:h-[22rem] lg:w-[45rem] lg:h-[25rem] object-cover object-center rounded-b-[25px] border-t-2 border-white"
                            src={project.image}
                        />
                        <div className="flex flex-col align-middle text-center items-center justify-center px-8 py-10 relative z-10 w-[17rem] h-[30rem] md:w-[35rem] md:h-[22rem] lg:w-[45rem] lg:h-[25rem] border-t-2 border-white bg-gray-900 opacity-0 hover:opacity-90 rounded-b-[25px] transition ease-in-out">
                            <h1 className="title-font text-3xl font-medium text-white">
                                {project.title}
                            </h1>
                            <h2 className="tracking-widest text-lg title-font font-medium text-chill-teal mb-3">
                                {project.subtitle}
                            </h2>
                            <p className="leading-relaxed mb-5 font-body text-sm text-gray-300">
                                {project.description}
                            </p>
                            <div className="flex flex-wrap mb-8 justify-center">
                                {project.tech?.map((tech) => {
                                    return (
                                        <Badge
                                            variant="gradient"
                                            gradient={{ from: 'indigo', to: 'cyan' }}
                                            className="m-1"
                                        >
                                            {tech}
                                        </Badge>
                                    )
                                })}
                            </div>
                            {project.link && (
                                <button className="text-center rounded-full">
                                    <a
                                        href={project.link}
                                        target="_blank"
                                        className="text-center text-white bg-chill-teal mt-10 px-4 pt-2 pb-3 rounded-full text-lg hover:text-chill-teal hover:bg-white transition ease-in-out"
                                    >
                                        check it out
                                    </a>
                                </button>
                            )}
                        </div>
                    </div>
                </BrowserFrame>
            ),
        }
    })

    return (
        <section id="projects" className="text-gray-400 body-font -z-50">
            <div className="animate-slip2 container px-5 pt-32 mx-auto flex flex-col align-middle text-center items-center justify-center">
                <div className="flex flex-col w-full mb-20">
                    <h1 className="title-font sm:text-5xl text-3xl font-medium text-white selection:text-royal-yellow selection:bg-none">
                        <span className="inline-block font-written text-royal-yellow selection:text-white mt-10 mb-7 mr-4 ">
                            Projects
                        </span>
                        over the years...
                    </h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-md text-justify font-body selection:bg-royal-yellow selection:text-black">
                        Feel free to play around with some of the things I've worked on alongside
                        other devs! You'll find that the projects were each built on its own unique
                        tech stack, each with its own distinct creative feel. I also designed most
                        of the UI and UX for all these projects.
                    </p>
                </div>
                <div className="w-[100wh]">
                    <ProjectSlider images={projectSlides} slidesToShow={3} />
                </div>
                <p className="lg:w-2/3 mx-auto leading-relaxed text-sm font-body mt-5 selection:bg-royal-yellow selection:text-black">
                    swipe or use arrow keys to navigate
                </p>
            </div>
        </section>
    )
}
