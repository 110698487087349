import React, { useState } from 'react'
import Slider from 'react-slick'
import './ProjectSlider.css'

const ProjectSlider = ({ images, slidesToShow = 3 }) => {
    const [imageIndex, setImageIndex] = useState(0)

    const settings = {
        centerMode: true,
        infinite: true,
        dots: false,
        speed: 300,
        slidesToShow: slidesToShow,
        centerPadding: '0',
        swipeToSlide: true,
        focusOnSelect: true,
        beforeChange: (current, next) => setImageIndex(next),
    }

    const templateImages = images.map((image, idx) => {
        return (
            <div className={idx === imageIndex ? 'activeSlide' : 'slide'} key={image.id}>
                <div className="flex justify-center">{image.render}</div>
            </div>
        )
    })

    return (
        <div className="w-[47.5rem] md:w-[100rem] lg:w-[125rem]">
            <Slider {...settings}>{templateImages}</Slider>
        </div>
    )
}

export default ProjectSlider
