import React from 'react'
import styled from 'styled-components'
import useSimpleMediaQuery from '../hooks/useSimpleMediaQuery'

const StyledFooter = styled.footer`
    flex-direction: column;
    height: auto;
    min-height: 70px;
    padding: 15px;
    text-align: center;
`

const StyledCredit = styled.div`
    color: var(--light-slate);
    font-family: var(--font-mono);
    font-size: var(--fz-xxs);
    line-height: 1;
    a {
        padding: 10px;
    }
`

const Footer = () => {
    const isDesktop = useSimpleMediaQuery(768)
    return (
        <StyledFooter>
            {isDesktop && (
                <div className="flex flex-row justify-center mt-5">
                    <a
                        href="https://www.linkedin.com/in/stefan-marbun-78b396195"
                        target="_blank"
                        aria-label="linkedIn"
                    >
                        <img
                            src="./linkedIn.svg"
                            className="w-8 h-8 m-5 hover:scale-150 hover:-translate-y-2 transition ease-in-out"
                        />
                    </a>
                    <a href="https://github.com/stefanmarbun12" target="_blank" aria-label="github">
                        <img
                            src="./github.svg"
                            className="w-8 h-8 m-5 hover:scale-150 hover:-translate-y-2 transition ease-in-out"
                        />
                    </a>
                    <a
                        href="https://www.facebook.com/stefan.marbun.5/"
                        target="_blank"
                        aria-label="facebook"
                    >
                        <img
                            src="./facebook.svg"
                            className="w-8 h-8 m-5 hover:scale-150 hover:-translate-y-2 transition ease-in-out"
                        />
                    </a>
                    <a
                        href="https://www.instagram.com/stefanmarbun12/?hl=en"
                        target="_blank"
                        aria-label="insta"
                    >
                        <img
                            src="./insta.svg"
                            className="w-8 h-8 m-5 hover:scale-150 hover:-translate-y-2 transition ease-in-out"
                        />
                    </a>
                </div>
            )}
            <StyledCredit tabindex="-1">
                <div className="flex flex-row align-middle text-center items-center justify-center">
                    <a href="https://github.com/stefanmarbun12" target="_blank">
                        <div className="hover:underline">designed and built by Stefan Marbun</div>
                    </a>
                    {isDesktop && <p className="px-5 py-1 text-xl">|</p>}
                    {isDesktop && (
                        <a href="./StefanMarbunResume_Web.pdf" target="_blank" download>
                            <div className="hover:underline">download my resume</div>
                        </a>
                    )}
                </div>
            </StyledCredit>
        </StyledFooter>
    )
}

export default Footer
