import React, { useState, useEffect } from 'react'
import Home from './components/Home'
import Navbar from './components/Navbar'
import About from './components/About'
import Projects from './components/Projects'
import Experience from './components/Experience'
import Contact from './components/Contact'
import Particles from 'react-tsparticles'
import Footer from './components/footer'
import MobileNavbar from './MobileNavbar'
import Loading from './components/Loading'
import useSimpleMediaQuery from './hooks/useSimpleMediaQuery'

export default function App() {
    const isDesktop = useSimpleMediaQuery(768)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setTimeout(stopLoading, 2500)
    }, [])

    const stopLoading = () => {
        setIsLoading(false)
    }

    return isLoading ? (
        <Loading />
    ) : (
        <main
            className={`text-gray-400 body-font min-w-screen-2xl overflow-clip animate-fade ${
                !isDesktop && 'overflow-hidden'
            }`}
        >
            <Particles
                className="tsparticles"
                options={{
                    background: {
                        color: {
                            value: '#0A192E',
                        },
                    },
                    fpsLimit: 60,
                    interactivity: {
                        detectsOn: 'window',
                        events: {
                            onClick: {
                                enable: false,
                                mode: 'push',
                            },
                            onHover: {
                                enable: true,
                                mode: 'bubble',
                            },
                            resize: true,
                        },
                        modes: {
                            bubble: {
                                distance: 100,
                                duration: 2,
                                opacity: 0.1,
                                size: 30,
                            },
                            push: {
                                quantity: 50,
                                duration: 2.0,
                            },
                            repulse: {
                                distance: 100,
                                duration: 3.0,
                            },
                        },
                    },
                    particles: {
                        color: {
                            value: ['#ffffff', '#F297A2', '#3F9AED', '#F297A2', '#3F9AED'],
                        },
                        links: {
                            color: '#ffffff',
                            distance: 150,
                            enable: true,
                            opacity: 0.0,
                            width: 1,
                        },
                        collisions: {
                            enable: true,
                        },
                        move: {
                            direction: 'none',
                            enable: true,
                            outMode: 'bounce',
                            random: false,
                            speed: 0.2,
                            straight: false,
                        },
                        number: {
                            density: {
                                enable: true,
                                value_area: 800,
                            },
                            value: 80,
                        },
                        opacity: {
                            random: true,
                            value: 0.3,
                        },
                        shape: {
                            type: 'circle',
                        },
                        size: {
                            random: true,
                            value: 5,
                        },
                    },
                    detectRetina: true,
                }}
            />
            {isDesktop ? <Navbar /> : <MobileNavbar />}
            <Home />
            <About />
            <Projects />
            <Experience />
            <Contact />
            <Footer />
        </main>
    )
}
