import React from 'react'
import useSimpleMediaQuery from '../hooks/useSimpleMediaQuery'

export default function Home({ menuOpen }) {
    const isLarge = useSimpleMediaQuery(1024)
    const isMedium = useSimpleMediaQuery(768)

    return (
        <section id="home" className="animate-slip2 -z-50">
            <div className="container mx-auto flex pb-20 md:flex-row flex-col items-center ">
                <div className="pt-60 md:pt-40 lg:pt-0 md:pl-12 lg:pl-20 lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col w-full md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                    <h1 className="min-w-[50rem] title-font md:text-6xl text-[2.5rem] mb-4 font-medium text-white selection:text-royal-yellow selection:bg-none">
                        Heya! I'm{' '}
                        <span className="inline-block font-written text-royal-yellow selection:text-white md:mb-5 ml-1">
                            Stef
                        </span>
                        ,
                        <br className="inline-block font-light" />
                        and I'm a{' '}
                        <span className="inline-block font-written text-royal-yellow selection:text-white ml-1">
                            Web Dev
                        </span>
                    </h1>
                    <p className="mx-auto md:mx-0 md:min-w-[30rem] mb-8 leading-relaxed md:pt-4 font-body selection:bg-royal-yellow selection:text-black text-xl md:text-2xl">
                        Because who doesn't love making awesome stuff?
                    </p>
                    <div className="md:min-w-[50rem] flex flex-col md:flex-row">
                        <button className="md:inline-flex text-center text-chill-teal focus:outline-none rounded-full hover:bg-chill-teal transition ease-in-out delay-150">
                            <a
                                href="#projects"
                                className="inline-flex pl-[3rem] md:pl-8 min-w-[19rem] text-center md:min-w-fit text-chill-teal border-2 border-chill-teal pt-4 pb-6 px-8 focus:outline-none hover:bg-white hover:border-white rounded-full text-2xl transition ease-in-out delay-150 hover:scale hover:-translate-y-2"
                            >
                                check out my projects...
                            </a>
                        </button>
                        <button className="md:ml-4 items-center text-center mt-5 md:mt-0 inline-flex focus:outline-none rounded-full hover:bg-royal-yellow transition ease-in-out delay-150">
                            <a
                                href="#contact"
                                className="inline-flex pl-[4.5rem] md:pl-8 min-w-[19rem] md:min-w-fit text-royal-yellow border-2 border-royal-yellow pt-4 pb-6 px-8 focus:outline-none hover:bg-white hover:text-intense-yellow hover:border-white rounded-full text-2xl transition ease-in-out delay-150 hover:scale hover:-translate-y-2"
                            >
                                or contact me 🤙
                            </a>
                        </button>
                    </div>
                </div>
                {(isLarge || !isMedium) && (
                    <div className="animate-wiggle mt-20 md:mt-0 pl-16 md:pl-0 lg:pt-0">
                        <img
                            alt="floating react astronaut"
                            src="./reactStronaut.png"
                            className={`${menuOpen && `-z-50`}`}
                        />
                    </div>
                )}
            </div>
            {!isLarge && isMedium && (
                <div className="animate-wiggle mt-20 md:mt-0 pl-[20rem] py-16">
                    <img alt="floating react astronaut" src="./reactStronaut.png" />
                </div>
            )}
        </section>
    )
}
