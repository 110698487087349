export const projects = [
    {
        title: 'DRIBBLR',
        subtitle: 'All-in-one basketball management',
        description:
            'A one-stop information storage for basketball associations, teams, matches and more. With features like fixture generation and player stat history, this was built as a capstone uni project.',
        tech: ['React', 'NextJS', 'MongoDB', 'ChakraUI'],
        image: './dribblr.gif',
        //link: 'https://dribblr.fit',
    },
    {
        title: 'TRUKK',
        subtitle: 'For all your food truck needs',
        description:
            'A customer engagement application inspired by EatClub, where customers can search for food trucks and vendors can manage orders, made for a web development subject.',
        tech: ['React', 'NodeJS', 'MongoDB', 'MaterialUI'],
        image: './trukk.gif',
        //link: 'https://elastic-shockley-0a4ba5.netlify.app/',
    },
    {
        title: 'VaccFind',
        subtitle: 'Search for nearby vaccine providers',
        description:
            'This is a healthcare application built in 2 days for the CodeBrew 2020 Hackathon. My team placed in the top 5 out of 30+ competing teams.',
        tech: ['React', 'Flask', 'MapBox'],
        image: './vaccfind.gif',
        link: 'https://github.com/stefanmarbun12/Codebrew2020',
    },
    {
        title: '2020 Simulator',
        subtitle: 'Recreating the chaos that was 2020',
        description:
            'Make new friends while social distancing and avoid getting infected by the ‘rona in this intense and highly original game! Built for the 2020 UniJam Hackathon.',
        tech: ['Unity', 'C#'],
        image: './2020simulator.gif',
        link: 'https://2020-simulator.itch.io/2020-simulator-unijam',
    },
]
