import React, { useState, useEffect } from 'react'

export default function MobileNavbar() {
    const [animateHeader, setAnimateHeader] = useState(false)
    const [scrollingUp, setScrollingUp] = useState(true)

    var lastScroll = document.documentElement.scrollTop || document.body.scrollTop

    useEffect(() => {
        const listener = () => {
            if (window.scrollY > 70) {
                setAnimateHeader(true)
            } else setAnimateHeader(false)

            let currentScroll = document.documentElement.scrollTop || document.body.scrollTop // Get Current Scroll Value

            if (
                currentScroll > 0 &&
                lastScroll <= currentScroll &&
                !(window.scrollY >= 0 && window.scrollY <= 200)
            ) {
                lastScroll = currentScroll
                setScrollingUp(false)
                setMenuOpen(false)
            } else {
                lastScroll = currentScroll
                setScrollingUp(true)
            }
        }
        window.addEventListener('scroll', listener)

        return () => {
            window.removeEventListener('scroll', listener)
        }
    }, [])

    const [menuOpen, setMenuOpen] = useState(false)
    const [dissolve, setDissolve] = useState(false)
    const [appear, setAppear] = useState(false)

    return (
        <div className="flex sticky top-0 z-50 ">
            <header
                className={`absolute w-[100vw] border-gray-700 ${
                    scrollingUp ? 'animate-slipin' : 'animate-slipout translate-y-[-150px]'
                } ${
                    animateHeader &&
                    !menuOpen &&
                    'transition ease-in-out duration-500 shadow-xl bg-cool-blue backdrop-filter backdrop-blur-3xl bg-opacity-10 border-b '
                } ${
                    menuOpen &&
                    'bg-cool-blue backdrop-filter backdrop-blur-3xl bg-opacity-10 h-[100vh] bg-blend-overlay backdrop-brightness-75 overflow-y-hidden'
                }`}
            >
                <nav className="py-1 px-1 flex flex-wrap items-center">
                    <div className="h-14 w-40 m-5 cursor-pointer">
                        <a href="#home">
                            <img
                                alt="logo"
                                src="./stefLogo.png"
                                onClick={() => setMenuOpen(false)}
                            />
                        </a>
                    </div>
                    <div className="flex relative">
                        <img
                            alt="menu"
                            src="./menuBurger.png"
                            className="h-2 w-2 m-5 ml-auto z-20 opacity-0"
                        />
                        <img
                            alt="close"
                            src="./closeMenu.png"
                            className="absolute h-2 w-2 m-5 ml-auto opacity-0"
                        />
                    </div>
                    {!menuOpen ? (
                        <img
                            alt="menu"
                            src="./menuBurger.png"
                            className={`h-12 w-16 m-5 ml-auto ${appear && 'animate-appear'} z-20 ${
                                dissolve && 'animate-dissolve'
                            }`}
                            onClick={() => {
                                setDissolve(true)
                            }}
                            onAnimationEnd={() => {
                                if (dissolve) {
                                    setDissolve(false)
                                    setMenuOpen(true)
                                    setAppear(true)
                                } else {
                                    setAppear(false)
                                }
                            }}
                        />
                    ) : (
                        <img
                            alt="close"
                            src="./closeMenu.png"
                            className={`h-12 w-[3.25rem] m-5 ml-auto ${
                                appear && 'animate-appear'
                            } ${dissolve && 'animate-dissolve'}`}
                            onClick={() => setDissolve(true)}
                            onAnimationEnd={() => {
                                if (dissolve) {
                                    setDissolve(false)
                                    setMenuOpen(false)
                                    setAppear(true)
                                } else {
                                    setAppear(false)
                                }
                            }}
                        />
                    )}
                </nav>
                {menuOpen && (
                    <div className="grid grid-flow-col-dense grid-rows-6 h-[80vh]">
                        <nav
                            className={`row-span-5 relative flex flex-col text-4xl justify-center text-center ${
                                appear && 'animate-appear'
                            } ${dissolve && 'animate-dissolve'} text-white`}
                        >
                            <a href="#about" className="p-10" onClick={() => setMenuOpen(false)}>
                                about
                            </a>
                            <a href="#projects" className="p-10" onClick={() => setMenuOpen(false)}>
                                projects
                            </a>
                            <a
                                href="#experience"
                                className="p-10"
                                onClick={() => setMenuOpen(false)}
                            >
                                experience
                            </a>
                            <a href="#contact" className="p-10" onClick={() => setMenuOpen(false)}>
                                contact
                            </a>
                        </nav>
                        <div
                            className={`row-span-1 flex flex-row justify-center align-middle mt-5 ${
                                appear && 'animate-appear'
                            } ${dissolve && 'animate-dissolve'} text-white`}
                        >
                            <a
                                href="https://www.linkedin.com/in/stefan-marbun-78b396195"
                                target="_blank"
                                aria-label="linkedIn"
                            >
                                <img src="./linkedIn.svg" className="w-12 h-12 m-5" />
                            </a>
                            <a
                                href="https://github.com/stefanmarbun12"
                                target="_blank"
                                aria-label="github"
                            >
                                <img src="./github.svg" className="w-12 h-12 m-5" />
                            </a>
                            <a
                                href="https://www.facebook.com/stefan.marbun.5/"
                                target="_blank"
                                aria-label="facebook"
                            >
                                <img src="./facebook.svg" className="w-12 h-12 m-5" />
                            </a>
                            <a
                                href="https://www.instagram.com/stefanmarbun12/?hl=en"
                                target="_blank"
                                aria-label="insta"
                            >
                                <img src="./insta.svg" className="w-12 h-12 m-5" />
                            </a>
                        </div>
                    </div>
                )}
            </header>
        </div>
    )
}
