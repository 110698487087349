import { useState } from 'react'
import { Badge } from '@mantine/core'
import useSimpleMediaQuery from '../hooks/useSimpleMediaQuery'

export default function About() {
    const isDesktop = useSimpleMediaQuery(1024)

    const languages = ['Python', 'Java', 'JavaScript', 'C', 'C#', 'Haskell']
    const webDev = ['React', 'HTML', 'CSS', 'NodeJS', 'NextJS', 'MongoDB', 'Spring']
    const design = ['Photoshop', 'Illustrator', 'Adobe XD']
    const tools = [
        'Adobe XD',
        'Figma',
        'Photoshop',
        'Illustrator',
        'Tailwind CSS',
        'VS Code',
        'IntelliJ IDEA',
        'iTerm',
        'Postman',
        'MySQL',
        'MongoDB',
    ]

    const desc =
        'Three things defined my childhood back in my home country Indonesia: video games, graphic design, and computers. So it’s no wonder that I ended up in the tech industry! I’ve found that my passion for all things design very nicely complements my programming skills, which led me into pursuing web and software development as a career. Is this the right field for me? Well, I just hope my work can speak for itself...'

    const [showToolsList, setShowToolsList] = useState(false)

    return (
        <section id="about" className="text-gray-400 body-font -z-50">
            <div className="animate-slip2 container px-5 pt-36 mx-auto flex flex-col text-left justify-start">
                <div className="flex flex-row">
                    <img
                        alt="stefan face photo"
                        src="./stefSmallCircle.png"
                        className="animate-float w-60 h-60 lg:w-80 lg:h-80 -ml-20 mr-5 md:ml-20 md:mr-14"
                    />
                    <div className="flex flex-col w-full mt-56">
                        <h1 className="title-font sm:text-5xl text-3xl font-medium text-white selection:text-royal-yellow selection:bg-none">
                            A bit about
                            <span className="lg:inline-block font-written text-royal-yellow selection:text-white my-10 ml-4 ">
                                Me
                            </span>
                        </h1>
                        {isDesktop && (
                            <p className="lg:w-[100%] mx-auto lg:mt-0 leading-relaxed text-md text-left font-body selection:bg-royal-yellow selection:text-black pr-0 md:pr-10">
                                {desc}
                            </p>
                        )}
                    </div>
                </div>
                {!isDesktop && (
                    <p className="lg:w-[100%] mx-auto mt-5 md:mt-10 lg:mt-0 leading-relaxed text-justify text-md md:text-md font-body selection:bg-royal-yellow selection:text-black">
                        {desc}
                    </p>
                )}
                <div className="container flex flex-col align-middle text-center items-center justify-center mt-10">
                    <h2 className="title-font sm:text-4xl text-2xl font-medium text-white selection:text-chill-teal selection:bg-none mb-5">
                        My{' '}
                        <span className="lg:inline-block font-written text-chill-teal selection:text-white mt-10 mr-3 ">
                            Skills
                        </span>
                    </h2>
                    <div className="flex flex-wrap align-middle items-center justify-center mb-4 md:mb-2">
                        <body className="font-body mr-5 text-md md:text-xl w-full md:w-max">
                            Languages:
                        </body>
                        {languages.map((skill) => {
                            return (
                                <Badge
                                    variant="gradient"
                                    gradient={{ from: 'indigo', to: 'red' }}
                                    className="m-1"
                                    size="xl"
                                >
                                    {skill}
                                </Badge>
                            )
                        })}
                    </div>
                    <div className="flex flex-wrap align-middle items-center justify-center mb-4 md:mb-2">
                        <body className="font-body mr-5 text-md md:text-xl w-full md:w-max">
                            Web Development:
                        </body>
                        {webDev.map((skill) => {
                            return (
                                <Badge
                                    variant="gradient"
                                    gradient={{ from: 'indigo', to: 'cyan' }}
                                    className="m-1"
                                    size="xl"
                                >
                                    {skill}
                                </Badge>
                            )
                        })}
                    </div>
                    <div className="flex flex-wrap align-middle items-center justify-center mb-4 md:mb-2">
                        <body className="font-body mr-5 text-md md:text-xl w-full md:w-max">
                            UI/UX Design:
                        </body>
                        {design.map((skill) => {
                            return (
                                <Badge
                                    variant="gradient"
                                    gradient={{ from: 'teal', to: 'yellow' }}
                                    className="m-1"
                                    size="xl"
                                >
                                    {skill}
                                </Badge>
                            )
                        })}
                    </div>
                </div>
                <div className="flex flex-row items-center justify-center">
                    <h2 className="title-font sm:text-4xl text-2xl font-medium text-white selection:text-chill-teal selection:bg-none">
                        <span className="lg:inline-block font-written text-chill-teal selection:text-white my-10 mr-3">
                            Tools
                        </span>
                        {!isDesktop && <br />}I use
                    </h2>
                    <div className="flex flex-col align-middle text-center items-center justify-center mr-5 md:ml-14">
                        <div className="animate-float flex relative ml-3">
                            <img
                                alt="tools icons"
                                src="./stefTools.png"
                                className={`mt-14  md:w-96 md:h-96 transition ease-in-out ${
                                    showToolsList && 'opacity-20'
                                }`}
                                onMouseOver={() => setShowToolsList(true)}
                                onMouseOut={() => setShowToolsList(false)}
                            />
                            <ul
                                className={`w-64 md:w-96 mt-5 md:mt-10 list-disc absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] font-body text-lg text-white opacity-0 transition ease-in-out ${
                                    showToolsList ? 'opacity-100' : 'opacity-0'
                                }`}
                                onClick={() => setShowToolsList(!showToolsList)}
                                onMouseOver={() => setShowToolsList(true)}
                            >
                                {tools.map((tool) => {
                                    return (
                                        <Badge className="mx-1 my-2" size={isDesktop ? 'lg' : 'sm'}>
                                            {tool}
                                        </Badge>
                                    )
                                })}
                            </ul>
                        </div>
                        <p className="leading-relaxed text-sm font-body mt-10 selection:bg-royal-yellow selection:text-black">
                            tap or hover for list view
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
