import React, { useState, useEffect } from 'react'

export default function Navbar() {
    const [animateHeader, setAnimateHeader] = useState(false)
    const [scrollingUp, setScrollingUp] = useState(true)

    var lastScroll = document.documentElement.scrollTop || document.body.scrollTop

    useEffect(() => {
        const listener = () => {
            if (window.scrollY > 0) {
                setAnimateHeader(true)
            } else setAnimateHeader(false)

            let currentScroll = document.documentElement.scrollTop || document.body.scrollTop // Get Current Scroll Value

            if (
                currentScroll > 0 &&
                lastScroll <= currentScroll &&
                !(window.scrollY >= 0 && window.scrollY <= 200)
            ) {
                lastScroll = currentScroll
                setScrollingUp(false)
            } else {
                lastScroll = currentScroll
                setScrollingUp(true)
            }
        }
        window.addEventListener('scroll', listener)

        return () => {
            window.removeEventListener('scroll', listener)
        }
    }, [])

    return (
        <header
            className={`position-fixed ${
                scrollingUp
                    ? 'sticky animate-slipin'
                    : 'sticky animate-slipout translate-y-[-150px]'
            } top-0 z-10 trasition ease-in-out duration-500 border-gray-700 ${
                animateHeader &&
                'shadow-xl bg-cool-blue backdrop-filter backdrop-blur-3xl bg-opacity-10 border-b '
            }`}
        >
            <div
                className={`sm:pl-16 md:pl-12 lg:pl-20 container mx-auto flex flex-wrap flex-col md:flex-row items-cente py-10 trasition ease-in-out duration-500 ${
                    animateHeader && 'py-5'
                }`}
            >
                <div className="animate-slip md:h-[4rem] md:w-[10rem] lg:h-[4.5rem] lg:w-[12rem] mr-5 transition ease-in-out delay-150 hover:scale-110">
                    <a href="#home">
                        <img alt="logo" src="./stefLogo.png" />
                    </a>
                </div>
                <nav className="animate-slip md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700 flex flex-wrap items-center md:text-xl lg:text-2xl justify-center">
                    <a
                        href="#about"
                        className="ml-5 mr-8 hover:text-white trasition ease-in-out duration-300"
                    >
                        0. about
                    </a>
                    <a
                        href="#projects"
                        className="mr-8 hover:text-white trasition ease-in-out duration-300"
                    >
                        1. projects
                    </a>
                    <a
                        href="#experience"
                        className="mr-8 hover:text-white trasition ease-in-out duration-300"
                    >
                        2. experience
                    </a>
                    <a
                        href="#contact"
                        className="mr-8 hover:text-white trasition ease-in-out duration-300"
                    >
                        3. contact
                    </a>
                </nav>
            </div>
        </header>
    )
}
