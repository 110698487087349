import React, { useState, useEffect } from 'react'
import Particles from 'react-tsparticles'

export default function Loading() {
    const [dots, setDots] = useState(0)

    const textOptions = [
        'Activating Skynet',
        'Loading',
        'Floating thru space',
        'Installing packages',
        'Binging Succession',
        'Finding love',
        'Going back in time',
        'Exploring Melbourne',
        'Playing Elden Ring',
    ]
    const [textChoice, setTextChoice] = useState(0)
    const dotsOptions = ['', '.', '..', '...']

    var intervalId = ''

    useEffect(() => {
        intervalId = setInterval(changeText, 200)
        setTextChoice(Math.floor(Math.random() * textOptions.length))
    }, [])

    var num = 0
    const changeText = () => {
        num++
        setDots(num % 4)
        if (num > 20) {
            clearInterval(intervalId)
        }
    }

    return (
        <div className="fixed flex align-middle text-center items-center justify-center w-[100vw] h-[100vh] bg-cool-blue overflow-hidden">
            <Particles
                className="tsparticles animate-fade"
                options={{
                    background: {
                        color: {
                            value: '#0A192E',
                        },
                    },
                    fpsLimit: 60,
                    interactivity: {
                        detectsOn: 'window',
                        events: {
                            onClick: {
                                enable: false,
                                mode: 'push',
                            },
                            onHover: {
                                enable: false,
                                mode: 'bubble',
                            },
                            resize: true,
                        },
                        modes: {
                            bubble: {
                                distance: 100,
                                duration: 2,
                                opacity: 0.1,
                                size: 30,
                            },
                            push: {
                                quantity: 50,
                                duration: 2.0,
                            },
                            repulse: {
                                distance: 100,
                                duration: 3.0,
                            },
                        },
                    },
                    particles: {
                        color: {
                            value: ['#ffffff'],
                        },
                        links: {
                            color: '#ffffff',
                            distance: 150,
                            enable: true,
                            opacity: 0.0,
                            width: 1,
                        },
                        collisions: {
                            enable: false,
                        },
                        move: {
                            direction: 'right',
                            enable: true,
                            outMode: 'out',
                            random: false,
                            speed: 7,
                            straight: true,
                        },
                        number: {
                            density: {
                                enable: true,
                                value_area: 800,
                            },
                            value: 80,
                        },
                        opacity: {
                            random: true,
                            value: 0.5,
                        },
                        shape: {
                            type: 'circle',
                        },
                        size: {
                            random: true,
                            value: 5,
                        },
                    },
                    detectRetina: true,
                }}
            />
            <div className="flex flex-col align-middle text-center animate-slip fixed">
                <div className="animate-spin w-40 h-40 md:w-60 md:h-60 self-center">
                    <img alt="loading" src="./floatyAstronaut.png" />
                </div>
                <h1 className="pt-32 md:pt-40 min-w-[20rem] max-w-[20rem] title-font md:text-4xl text-[2.5rem] mb-4 font-medium text-gray-400 selection:bg-none">
                    {textOptions[textChoice]}
                    {dotsOptions[dots]}
                </h1>
            </div>
        </div>
    )
}
